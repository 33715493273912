import * as React from 'react'
import galleryData from '../data/gallery-data'
import { css } from '@emotion/react'
import randomizeArray from "../utils/randomizeArray"
import FadeBlock from './FadeBlock'

export default function Gallery(props) {

    const { data } = props;

    let mediaData = galleryData;
    if ( data ) mediaData = data;

    const padArray = [56, 75, 90, 100, 110, 125, 130]
    // const randomPat = padArray[Math.floor(Math.random() * padArray.length)];

    const randomPadding = (arr) => {
        return arr[Math.floor(Math.random() * arr.length)]
    }

    const wrapperStyle = [
        css`
            position: relative;
            overflow: hidden;
            height: 0;
            width: 100%;
            padding-top: 100%;

            > div {
                position: absolute;
                height: 100%;
                width: 100%;
                margin: auto;
                top: 0; right: 0; left: 0; bottom: 0;
                object-fit: cover;
                
                
            } 
        `
    ]

    const [items, setItems] = React.useState([]);

    React.useEffect(() => {
        setItems(randomizeArray(mediaData, 20));
    }, [])

    return (

        <div className={`md:columns-3 columns-2 md:gap-x-8 md:-mb-8 gap-x-4 -mb-4 lg:gap-x-8 xl:gap-x-14 lg:-mb-8 xl:-mb-14 lg:columns-2`}>
            
            {
                items.map((img, index) => {

                    if (! img.image) return

                    return (
                        <div className={`md:mb-8 mb-4 lg:mb-8 xl:mb-14 item-${index}`} key={img.id}>
                            <FadeBlock direction={`bottom`} duration={index > 3 ? 500 : 0} cascade={false} wait={index > 3 ? 5 : 0} fraction={index > 3 ? 0.54 : 0}>
                                <div css={[
                                    wrapperStyle,
                                    css`
                                        padding-top: ${randomPadding(padArray)}%;
                                        box-shadow: var(--shadow-4);
                                    `
                                ]}
                                
                                > {img.image} 
                            </div>
                            </FadeBlock>
                            
                        </div>
                    )
                })
            }
        </div>
    )
}