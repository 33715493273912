import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { nanoid } from 'nanoid'

const imgSettings = {
    quality: 60,
    formats: ["auto", "webp", "avif"],
    className: `hoochie`,
    loading: `eager`,
    placeholder: `blurred`
}

export default [
    
    {
        id: nanoid(),
        image: <StaticImage
        src="../images/selects/hohn_selects_1.jpg"
        alt="Hohn"
        {...imgSettings}
    />,
    },
    {
        id: nanoid(),
        image: <StaticImage
        src="../images/selects/hohn_selects_2.jpg"
        alt="Hohn"
        {...imgSettings}
    />,
    },
    {
        id: nanoid(),
        image: <StaticImage
        src="../images/selects/hohn_selects_3.jpg"
        alt="Hohn"
        {...imgSettings}
    />,
    },
    {
        id: nanoid(),
        image: <StaticImage
        src="../images/selects/hohn_selects_4.jpg"
        alt="Hohn"
        {...imgSettings}
    />,
    },
    {
        id: nanoid(),
        image: <StaticImage
        src="../images/selects/hohn_selects_5.jpg"
        alt="Hohn"
        {...imgSettings}
    />,
    },
    {
        id: nanoid(),
        image: <StaticImage
        src="../images/selects/hohn_selects_6.jpg"
        alt="Hohn"
        {...imgSettings}
    />,
    },
    {
        id: nanoid(),
        image: <StaticImage
        src="../images/selects/hohn_selects_7.jpg"
        alt="Hohn"
        {...imgSettings}
    />,
    },
    {
        id: nanoid(),
        image: <StaticImage
        src="../images/selects/hohn_selects_8.jpg"
        alt="Hohn"
        {...imgSettings}
    />,
    },
    {
        id: nanoid(),
        image: <StaticImage
        src="../images/selects/hohn_selects_9.jpg"
        alt="Hohn"
        {...imgSettings}
    />,
    },
    {
        id: nanoid(),
        image: <StaticImage
        src="../images/selects/hohn_selects_10.jpg"
        alt="Hohn"
        {...imgSettings}
    />,
    },
    {
        id: nanoid(),
        image: <StaticImage
        src="../images/selects/hohn_selects_11.jpg"
        alt="Hohn"
        {...imgSettings}
    />,
    },
    {
        id: nanoid(),
        image: <StaticImage
        src="../images/selects/hohn_selects_12.jpg"
        alt="Hohn"
        {...imgSettings}
    />,
    },
    {
        id: nanoid(),
        image: <StaticImage
        src="../images/selects/hohn_selects_13.jpg"
        alt="Hohn"
        {...imgSettings}
    />,
    },
    {
        id: nanoid(),
        image: <StaticImage
        src="../images/selects/hohn_selects_14.jpg"
        alt="Hohn"
        {...imgSettings}
    />,
    },
    {
        id: nanoid(),
        image: <StaticImage
        src="../images/selects/hohn_selects_15.jpg"
        alt="Hohn"
        {...imgSettings}
    />,
    },
]