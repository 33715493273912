import React from 'react'
import Layout from '../components/layout/Layout'
import Seo from '../components/layout/Seo'
import Section from '../components/layout/Section'
import Gallery from '../components/Gallery'

export default function GalleryPage() {

    return (
        <Layout>
        <Seo title="Gallery" description={`A disparate collection of images and illustrations.`} />
        
        <Section containerSize={`md`} sectionName={`Gallery Area`}>

            <Gallery />

        </Section>

       
        
    
        </Layout>
    )
}